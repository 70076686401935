import React from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const VideoPlayIcon = () => {
	return (
		<AccessibleIcon.Root label={'Video Play Icon'}>
			<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.68451 16.444 4.10977 12.0425 4.086C7.64111 4.06246 4.04732 7.59876 4 12V12.172ZM10 16.5V7.5L16 12L10 16.5Z'
					fill='white'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default VideoPlayIcon;
