import React from 'react';
import styles from '../../products/ProductTags/ProductTags.module.scss';
import Skeleton from './Skeleton';

const TagsSkeleton = () => {
	return (
		<div className={styles.container}>
			{[...Array(3)].map((__, i) => (
				<Skeleton key={i} height={17} width={70} type={'rect'} />
			))}
		</div>
	);
};

export default TagsSkeleton;
