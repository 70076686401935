import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Star = ({ color, stroke }) => {
	return (
		<AccessibleIcon.Root label='star'>
			<svg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M10 0L12.9389 5.95492L19.5106 6.90983L14.7553 11.5451L15.8779 18.0902L10 15L4.12215 18.0902L5.24472 11.5451L0.489435 6.90983L7.06107 5.95492L10 0Z'
					fill={color}
					stroke={stroke}
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Star;
