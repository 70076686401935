import { forwardRef } from 'react';
import { ComponentProps } from '@ts/components';
import { SidebarLayout } from '@components';

const Images = forwardRef<HTMLDivElement, ComponentProps<HTMLDivElement>>(({ children, ...rest }, forwardedRef) => {
	return (
		<SidebarLayout.Content ref={forwardedRef} {...rest}>
			{children}
		</SidebarLayout.Content>
	);
});

Images.displayName = 'Images';

export default Images;
