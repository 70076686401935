import { forwardRef } from 'react';
import { ComponentProps } from '@ts/components';
import { SidebarLayout } from '@components';

const Details = forwardRef<HTMLDivElement, ComponentProps<HTMLDivElement>>(({ children, ...rest }, forwardedRef) => {
	return (
		<SidebarLayout.Details ref={forwardedRef} {...rest}>
			{children}
		</SidebarLayout.Details>
	);
});

Details.displayName = 'Details';

export default Details;
