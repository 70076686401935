import { forwardRef } from 'react';
import { ComponentProps } from '@ts/components';
import { SidebarLayout } from '@components';

const Reviews = forwardRef<HTMLDivElement, ComponentProps<HTMLDivElement>>(({ children, ...rest }, forwardedRef) => {
	return (
		<SidebarLayout.Reviews ref={forwardedRef} {...rest}>
			{children}
		</SidebarLayout.Reviews>
	);
});

Reviews.displayName = 'Reviews';

export default Reviews;
