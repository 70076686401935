import { useCallback, useState } from 'react';
import { Button, Flex, Heading, Img, Modal, SwatchController } from '@components';
import styles from './FrameColorOptions.module.scss';

const FrameColorOptions = ({ title, options, callback, selected, variantImages }) => {
	const [activeColor, setActiveColor] = useState(selected);
	const [isChangedActiveColor, setIsChangedActiveColor] = useState(false);
	const image = variantImages?.[activeColor]?.[0];

	const handleSwatchChange = useCallback(
		({ option, type }) => {
			if (type === 'frame') {
				setActiveColor(option);
				setIsChangedActiveColor(option !== selected);
			}
		},
		[setActiveColor, setIsChangedActiveColor, selected]
	);

	const handleEditColor = useCallback(() => {
		callback(activeColor);
		setIsChangedActiveColor(false);
	}, [activeColor, callback]);

	const buttonLabel = isChangedActiveColor ? 'Save Color' : 'Edit Color';
	const dataAttributes = isChangedActiveColor ? { 'data-save-color': activeColor } : { 'data-edit-color': true };

	return (
		<Modal>
			<Modal.Trigger asChild>
				<Button color='white'>Edit Color</Button>
			</Modal.Trigger>
			<Modal.Content className={styles.content}>
				<Flex className={styles.cardContainer} column>
					<Flex justify='center' className={styles.cardImage}>
						<Img src={image?.url} title={title} sizes='(min-width: 76.8em) 24em, 10em' />
					</Flex>
					<Flex column center className={styles.cardSection}>
						<Heading tag='h5' className={styles.title}>{`The ${title}`}</Heading>
						<SwatchController
							alignment='left'
							options={options}
							callback={handleSwatchChange}
							selected={activeColor}
							type='frame'
							title='Base Frame Color'
							name={title}
							activateOnHover
						/>
						<Modal.Close asChild>
							<Button
								label={buttonLabel}
								color='green'
								size='medium'
								fullWidth
								onClick={handleEditColor}
								style={{ padding: '0.5rem 1.6rem' }}
								{...dataAttributes}
							/>
						</Modal.Close>
					</Flex>
				</Flex>
			</Modal.Content>
		</Modal>
	);
};

export default FrameColorOptions;
