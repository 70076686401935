import { FC } from 'react';
import { Img, LabelText, Lozenge, LozengeProps } from '@components';
import styles from './ProductTags.module.scss';

export interface ProductTagsProps {
	productTags: Array<LozengeProps>;
}

const ProductTags: FC<ProductTagsProps> = ({ productTags }) => {
	return (
		<div className={styles.container}>
			{productTags.map(productTag => {
				const { alignment, shape, text, extraClasses, url, backgroundColor, color, emoji, image } = productTag;
				return (
					<Lozenge
						key={text}
						alignment={alignment}
						shape={shape}
						extraClasses={extraClasses}
						url={url}
						backgroundColor={backgroundColor}
						color={color}
					>
						{emoji && <span>{emoji}</span>}
						{image && <Img src={image} alt='' height={17} width={21} />}
						<LabelText>{text}</LabelText>
					</Lozenge>
				);
			})}
		</div>
	);
};

ProductTags.displayName = 'ProductTags';

export default ProductTags;
