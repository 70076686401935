import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const ClockIcon = () => {
	return (
		<AccessibleIcon.Root label='Clock Icon'>
			<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_19159_1782)'>
					<path d='M3.38344 10.25H5.13776V9H2.9873L3.38344 10.25Z' fill='#4B4849' />
					<path d='M2.59145 7.745L5.60491 7.745V6.5L2.19531 6.5L2.59145 7.745Z' fill='#4B4849' />
					<path d='M9.49484 5.25L1.79848 5.25L1.40234 4H9.49484V5.25Z' fill='#4B4849' />
					<circle cx='9.5' cy='9.5' r='4.875' stroke='#4B4849' strokeWidth='1.25' />
					<path d='M11.5 1.5H7.5L7.90403 2.75L11.096 2.75L11.5 1.5Z' fill='#4B4849' />
					<path d='M10.1171 5L8.87207 5L8.87207 2.5L10.1171 2.5L10.1171 5Z' fill='#4B4849' />
					<path
						d='M10.118 9.375C10.118 9.72018 9.83934 10 9.49555 10C9.15175 10 8.87305 9.72018 8.87305 9.375L8.87305 7.125C8.87305 6.77982 9.15175 6.5 9.49555 6.5C9.83934 6.5 10.118 6.77982 10.118 7.125L10.118 9.375Z'
						fill='#4B4849'
					/>
					<circle
						cx='9.5'
						cy='9.50015'
						r='4.875'
						transform='rotate(-135 9.5 9.50015)'
						stroke='#4B4849'
						strokeWidth='1.25'
					/>
					<path
						d='M9.05239 8.95166C9.29647 8.70758 9.6914 8.70679 9.9345 8.94989C10.1776 9.19299 10.1768 9.58793 9.93274 9.83201L8.24175 11.623C7.99767 11.8671 7.60273 11.8679 7.35963 11.6248C7.11653 11.3817 7.11732 10.9867 7.3614 10.7427L9.05239 8.95166Z'
						fill='#4B4849'
					/>
				</g>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default ClockIcon;
