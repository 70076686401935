import { forwardRef } from 'react';
import { ComponentProps } from '@ts/components';
import { SidebarLayout } from '@components';

const Recommendations = forwardRef<HTMLDivElement, ComponentProps<HTMLDivElement>>(({ children, ...rest }, forwardedRef) => {
	return (
		<SidebarLayout.Recommendations ref={forwardedRef} {...rest}>
			{children}
		</SidebarLayout.Recommendations>
	);
});

Recommendations.displayName = 'Recommendations';

export default Recommendations;
