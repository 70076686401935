import { default as Sidebar } from '../ProductViewSidebar';
import Details from './Details';
import Images from './Images';
import Root from './Root';
import Recommendations from './Recommendations';
import Reviews from './Reviews';

const ProductView = {
	Root,
	Images,
	Sidebar,
	Details,
	Recommendations,
	Reviews,
};

export default ProductView;
