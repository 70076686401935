import React from 'react';
import Flex from '../Flex';
import styles from '../SwatchController/SwatchController.module.scss';
import Divider from '../Divider';
import Skeleton from './Skeleton';

const SwatchSkeleton = () => {
	return (
		<div className={styles['container']}>
			<Skeleton type={'line'} width={250} />
			<Divider />
			<Flex className={styles.swatchContainer}>
				<ul className={styles.list} style={{ flexWrap: 'wrap' }}>
					{[...Array(28)].map((__, i) => (
						<Skeleton key={i} height={32} width={32} type={'circle'} />
					))}
				</ul>
			</Flex>
		</div>
	);
};

export default SwatchSkeleton;
